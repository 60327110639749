export const isValidBase64 = (value: string) => {
  try {
    const decoded = Buffer.from(value, 'base64').toString('binary')
    const reEncoded = Buffer.from(decoded, 'binary').toString('base64')
    return reEncoded === value
  } catch {
    return false
  }
}

/**
 * Given a token, auto-login, reest password, unsubscribe etc..
 * extracts the userId from the base64 token
 * @param token
 * @returns Grpahql ID of the user
 */
export const getUserIdFromToken = (token: string) => {
  try {
    const decoded = Buffer.from(token, 'base64').toString('binary')
    let userId = decoded.split(':')[1]

    if (userId) {
      // Transforms this to a base64 ID for graphql
      userId = Buffer.from(`User-${userId}`, 'binary').toString('base64')
    }

    return userId
  } catch {
    return undefined
  }
}

export const redirectTo = (url: string, options?: { permanent?: boolean }) => {
  return {
    redirect: {
      destination: url,
      permanent: options?.permanent ?? false,
    },
  }
}
